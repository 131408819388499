export const parseJwt = (token: string): JwtContent => {
    return JSON.parse(window.atob(token.split('.')[1]).toString())
}

export const canAccessAdminSite = (profile: Profile) => {
    return profile ? profile.usertype === 1 || profile.usertype === 2 : false
}

export const userTypeToString = (profile: Profile) => {
    if (profile.usertype === 1) {
        return 'admin'
    } else if (profile.usertype === 2) {
        return 'billing'
    }
    return 'user'
}

export const projectRoleToString = (role: ProjectRole) => {
    if (role === 1) {
        return 'editor'
    } else if (role === 2) {
        return 'admin'
    }
    return 'reader'
}

export const getName = (
    profile: Profile | null,
    ignoreSalutation: boolean = process.env.REACT_APP_IGNORE_SALUTATION === 'true'
) => {
    if (profile) {
        if (ignoreSalutation) {
            return `${profile.firstname} ${profile.lastname}`.trim()
        } else {
            return `${profile.salutation} ${profile.firstname} ${profile.lastname}`.trim()
        }
    }
    return null
}

export const browserDetect = (): string => {
    if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
        return 'chrome'
    } else if (navigator.userAgent.match(/firefox|fxios/i)) {
        return 'firefox'
    } else if (navigator.userAgent.match(/safari/i)) {
        return 'safari'
    } else if (navigator.userAgent.match(/opr\//i)) {
        return 'opera'
    } else if (navigator.userAgent.match(/edg/i)) {
        return 'edge'
    }

    return 'unknown'
}

export const editionHasMultipleSeats = (seats: SeatAssignment[]): string[] => {
    const multipleEditions: string[] = []
    const editions: string[] = []
    seats.forEach((seat: SeatAssignment): void => {
        if (editions.includes(seat.subscriptionline.product.id)) {
            if (!multipleEditions.includes(seat.subscriptionline.product.id)) {
                multipleEditions.push(seat.subscriptionline.product.id)
            }
        } else {
            editions.push(seat.subscriptionline.product.id)
        }
    })
    return multipleEditions
}
