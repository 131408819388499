import { RpcError } from 'grpc-web'
import { RootState } from '../store'

export const selectProjects = (state: RootState): MyProject[] => state.projects.projects
export const selectArchivedProjects = (state: RootState): MyProject[] => state.projects.archivedProjects
export const selectProjectsStatus = (state: RootState): LoadStatus => state.projects.status
export const selectProjectsError = (state: RootState): RpcError => state.projects.error
export const selectProjectsSelectedProject = (state: RootState): ProjectInfo => state.projects.selected.project
export const selectProjectsSelectedProjectModified = (state: RootState): ProjectInfo =>
    state.projects.selected.projectModified
export const selectProjectsSelectedStatus = (state: RootState): LoadStatus => state.projects.selected.status
export const selectProjectsSelectedError = (state: RootState): RpcError => state.projects.selected.error
export const selectProjectsSelectedStorageLogin = (state: RootState): string => state.projects.selected.storageLogin
export const selectCreateProjectStatus = (state: RootState): LoadStatus => state.projects.createProjectStatus
export const selectCreateProjectError = (state: RootState): RpcError => state.projects.createProjectError
