import { LicenserClient } from './aimsunsf_grpc_web_pb'
import google_protobuf_empty_pb, { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { setAuthStatus, setAuthTokens } from '../redux/actions/authSlice'
import axios from 'axios'
import { parseJwt } from '../utils/utils'
import { store } from '../redux/store'
import { RpcError } from 'grpc-web'

proto.AimsunSF.v6 = require('../grpc/aimsunsf_pb.js')

const client = new LicenserClient(process.env.REACT_APP_LICENSER_URL!, null, null)

const getMetadata = () => {
    const state: StoreState = store.getState()
    const timeAllowed = 500
    const deadline: string = new Date(Date.now() + timeAllowed).toISOString()
    const token = process.env.REACT_APP_USE_ID_TOKEN === 'true' ? state.auth.idToken : state.auth.accessToken
    return { token, 'session-id': state.auth.uuid, deadline }
}

export const signOut = (): void => {
    localStorage.clear()
    const url = new URL(process.env.REACT_APP_AUTH_URL!)
    url.searchParams.append('redirect', window.location.href.split('?', 1)[0])
    window.location.href = url.href
}

const handleRefreshToken = () => {
    if (process.env.NODE_ENV === 'development') {
        console.log('handleRefreshToken', checkTokenExpiration())
    }
    return new Promise((resolve, reject) => {
        const state: StoreState = store.getState()
        const { accessToken, refreshToken } = state.auth
        store.dispatch(setAuthStatus('refreshing'))
        if (accessToken && refreshToken) {
            const jwtContent: JwtContent = parseJwt(accessToken)
            const params = new URLSearchParams()
            params.append('redirect', window.location.href)
            params.append('refresh_token', refreshToken)
            params.append('scope', jwtContent.scope)
            axios
                .post(`${process.env.REACT_APP_AUTH_URL}/api/token`, params)
                .then(response => {
                    store.dispatch(
                        setAuthTokens({
                            accessToken: response.data.access_token,
                            refreshToken: response.data.refresh_token,
                            idToken: response.data.id_token,
                        })
                    )
                    store.dispatch(setAuthStatus('authenticated'))
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response.data.name === 'NotAuthorizedException') {
                        signOut()
                    }
                    store.dispatch(setAuthStatus('unauthenticated'))
                    reject(error.response.data)
                })
        } else {
            signOut()
        }
    })
}

export const checkToken = (): void => {
    const state: StoreState = store.getState()
    const { accessToken, refreshToken } = state.auth
    const searchParams: URLSearchParams = new URL(window.location.href).searchParams
    if ((!accessToken || !refreshToken) && !searchParams.get('token')) {
        signOut()
    }
    if (searchParams.get('token') || searchParams.get('refresh_token')) {
        store.dispatch(
            setAuthTokens({
                accessToken: searchParams.get('token') || '',
                refreshToken: searchParams.get('refresh_token') || '',
                idToken: searchParams.get('id_token') || '',
            })
        )
        window.location.href = window.location.href.split('?', 1)[0]
    }
}

export const checkTokenExpiration = (): boolean => {
    const state: StoreState = store.getState()
    const { accessToken } = state.auth
    const data: JwtContent = parseJwt(accessToken)
    if (Date.now() >= data.exp * 1000) {
        return true
    }
    return false
}

export const commonCallback: CommonCallbackInputs = <T>(
    resolve: Resolve<T>,
    reject: Reject,
    error: RpcError,
    response: any
): void => {
    if (error) {
        if (process.env.NODE_ENV === 'development') {
            console.log(error.code, error.message)
        }
        const state: StoreState = store.getState()
        const { status } = state.auth
        if (error.code === 16) {
            if (status !== 'refreshing' && checkTokenExpiration()) {
                handleRefreshToken()
                    .then((): void => window.location.reload())
                    .catch((refreshError: string): void => {
                        store.dispatch(setAuthStatus('failed'))
                        reject(refreshError)
                    })
            } else {
                store.dispatch(setAuthStatus('failed'))
            }
        }
        reject({ ...error, code: String(error.code) })
    } else {
        if (process.env.NODE_ENV === 'development') {
            console.log(response.toObject() as T)
        }
        resolve(response.toObject() as T)
    }
}

export const callPing = (): Promise<Version> => {
    return new Promise((resolve: Resolve<Version>, reject: Reject): void => {
        const request = new Empty()
        client.ping(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.Version): void =>
            commonCallback<Version>(resolve, reject, error, response)
        )
    })
}

export const callProfile = (): Promise<Profile> => {
    return new Promise((resolve: Resolve<Profile>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.profile(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.Profile): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUpdateProfile = (data: Profile): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => {
            if (key === 'address') {
                return Object.keys(data.address).map(key => data.address[key as keyof Address])
            } else if (key === 'usertype') {
                return data.usertype
            } else {
                return data[key as keyof Profile]
            }
        })
        const request = new proto.AimsunSF.v6.Profile(dataArray)
        client.updateProfile(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callSeatAssignments = (): Promise<SeatAssignmentsResponse> => {
    return new Promise((resolve: Resolve<SeatAssignmentsResponse>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.seatAssignments(
            request,
            getMetadata(),
            (error: RpcError, response: proto.AimsunSF.v6.SeatAssignmentsResponse): void =>
                commonCallback(resolve, reject, error, response)
        )
    })
}

export const callProjects = (): Promise<MyProject[]> => {
    return new Promise((resolve: Resolve<any>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        const stream = client.projects(request, getMetadata())
        const list: MyProject[] = []
        stream.on('error', (error: RpcError) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(error.code, error.message)
            }
            reject({ ...error, code: String(error.code) })
        })
        stream.on('data', (response: any) => {
            list.push(response.toObject())
        })
        stream.on('end', () => {
            if (process.env.NODE_ENV === 'development') {
                console.log(list)
            }
            resolve(list)
        })
    })
}

export const callArchivedProjects = (): Promise<MyProject[]> => {
    return new Promise((resolve: Resolve<any>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        const stream = client.archivedProjects(request, getMetadata())
        const list: MyProject[] = []
        stream.on('error', (error: RpcError) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(error.code, error.message)
            }
            reject({ ...error, code: String(error.code) })
        })
        stream.on('data', (response: any) => {
            list.push(response.toObject())
        })
        stream.on('end', () => {
            if (process.env.NODE_ENV === 'development') {
                console.log(list)
            }
            resolve(list)
        })
    })
}

export const callProject = (projectId: string): Promise<ProjectInfo> => {
    return new Promise((resolve: Resolve<ProjectInfo>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ProjectId([projectId])
        client.project(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callCreateProject = (data: CreateProject): Promise<ProjectId> => {
    return new Promise((resolve: Resolve<ProjectId>, reject: Reject): void => {
        const project = new proto.AimsunSF.v6.Project()
        project.setName(data.project.name)
        project.setDescription(data.project.description)
        project.setUrl(data.project.url)
        const request = new proto.AimsunSF.v6.CreateProject()
        request.setProject(project)
        request.setAdmin(data.admin)
        client.createProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUpdateProject = (data: Project): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => data[key as keyof Project])
        const request = new proto.AimsunSF.v6.Project(dataArray)
        client.updateProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callActivateProject = (projectId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ProjectId([projectId])
        client.activateProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callArchiveProject = (projectId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ProjectId([projectId])
        client.archiveProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callRemoveProject = (projectId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ProjectId([projectId])
        client.removeProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callAddProjectUser = (data: AddProjectUserData): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => data[key as keyof AddProjectUserData])
        const request = new proto.AimsunSF.v6.AddProjectUserData(dataArray)
        client.addProjectUser(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callRemoveProjectUser = (data: RemoveProjectUserData): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => data[key as keyof RemoveProjectUserData])
        const request = new proto.AimsunSF.v6.AddProjectUserData(dataArray)
        client.removeProjectUser(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUpdateProjectUserRole = (data: UpdateProjectUserRole): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => data[key as keyof UpdateProjectUserRole])
        const request = new proto.AimsunSF.v6.UpdateProjectUserRole(dataArray)
        client.updateProjectUserRole(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callStorageForProject = (projectId: string): Promise<StorageForProject> => {
    return new Promise((resolve: Resolve<StorageForProject>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ProjectId([projectId])
        client.storageForProject(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}
