// source: aimsunsf.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.AimsunSF.v6.ACK', null, global);
goog.exportSymbol('proto.AimsunSF.v6.AccountProfile', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ActivationData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ActivationResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.AddProjectUserData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.AddUserData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Address', null, global);
goog.exportSymbol('proto.AimsunSF.v6.AssignRightData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.AssignSeatData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Billing', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ChangeUserTypeData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.CreateProject', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Email', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ExtendData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.FederatedResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.File', null, global);
goog.exportSymbol('proto.AimsunSF.v6.FileType', null, global);
goog.exportSymbol('proto.AimsunSF.v6.InUseActivationInfo', null, global);
goog.exportSymbol('proto.AimsunSF.v6.LicenseType', null, global);
goog.exportSymbol('proto.AimsunSF.v6.MyProject', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Product', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Profile', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Project', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ProjectId', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ProjectInfo', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ProjectRole', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ProjectUser', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ReleaseData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ReleaseResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.ReleaseRightData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.RemoveProjectUserData', null, global);
goog.exportSymbol('proto.AimsunSF.v6.RightInfo', null, global);
goog.exportSymbol('proto.AimsunSF.v6.RightsResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.SeatAssignment', null, global);
goog.exportSymbol('proto.AimsunSF.v6.SeatAssignmentState', null, global);
goog.exportSymbol('proto.AimsunSF.v6.SeatAssignmentsResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.StorageForProject', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Subscription', null, global);
goog.exportSymbol('proto.AimsunSF.v6.SubscriptionLine', null, global);
goog.exportSymbol('proto.AimsunSF.v6.SubscriptionsResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.TokenForRight', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UpdateProjectUserRole', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserId', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserInfo', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserResponse', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserToken', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserTokenStatus', null, global);
goog.exportSymbol('proto.AimsunSF.v6.UserType', null, global);
goog.exportSymbol('proto.AimsunSF.v6.Version', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Version.displayName = 'proto.AimsunSF.v6.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Email.displayName = 'proto.AimsunSF.v6.Email';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.FederatedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.FederatedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.FederatedResponse.displayName = 'proto.AimsunSF.v6.FederatedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.UserToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.UserToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.UserToken.displayName = 'proto.AimsunSF.v6.UserToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ActivationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ActivationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ActivationData.displayName = 'proto.AimsunSF.v6.ActivationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ActivationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ActivationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ActivationResponse.displayName = 'proto.AimsunSF.v6.ActivationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ExtendData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ExtendData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ExtendData.displayName = 'proto.AimsunSF.v6.ExtendData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ReleaseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ReleaseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ReleaseData.displayName = 'proto.AimsunSF.v6.ReleaseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ReleaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ReleaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ReleaseResponse.displayName = 'proto.AimsunSF.v6.ReleaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.InUseActivationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.InUseActivationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.InUseActivationInfo.displayName = 'proto.AimsunSF.v6.InUseActivationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.RightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.RightInfo.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.RightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.RightInfo.displayName = 'proto.AimsunSF.v6.RightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.RightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.RightsResponse.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.RightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.RightsResponse.displayName = 'proto.AimsunSF.v6.RightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ACK = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ACK, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ACK.displayName = 'proto.AimsunSF.v6.ACK';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.TokenForRight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.TokenForRight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.TokenForRight.displayName = 'proto.AimsunSF.v6.TokenForRight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.AssignRightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.AssignRightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.AssignRightData.displayName = 'proto.AimsunSF.v6.AssignRightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ReleaseRightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ReleaseRightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ReleaseRightData.displayName = 'proto.AimsunSF.v6.ReleaseRightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Address.displayName = 'proto.AimsunSF.v6.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.AccountProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.AccountProfile.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.AccountProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.AccountProfile.displayName = 'proto.AimsunSF.v6.AccountProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Profile.displayName = 'proto.AimsunSF.v6.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.UserInfo.displayName = 'proto.AimsunSF.v6.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.UserResponse.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.UserResponse.displayName = 'proto.AimsunSF.v6.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ChangeUserTypeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ChangeUserTypeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ChangeUserTypeData.displayName = 'proto.AimsunSF.v6.ChangeUserTypeData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.UserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.UserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.UserId.displayName = 'proto.AimsunSF.v6.UserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Product.displayName = 'proto.AimsunSF.v6.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.SubscriptionLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.SubscriptionLine.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.SubscriptionLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.SubscriptionLine.displayName = 'proto.AimsunSF.v6.SubscriptionLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.Subscription.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Subscription.displayName = 'proto.AimsunSF.v6.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.SubscriptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.SubscriptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.SubscriptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.SubscriptionsResponse.displayName = 'proto.AimsunSF.v6.SubscriptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.SeatAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.SeatAssignment.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.SeatAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.SeatAssignment.displayName = 'proto.AimsunSF.v6.SeatAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.SeatAssignmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.SeatAssignmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.SeatAssignmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.SeatAssignmentsResponse.displayName = 'proto.AimsunSF.v6.SeatAssignmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.AssignSeatData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.AssignSeatData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.AssignSeatData.displayName = 'proto.AimsunSF.v6.AssignSeatData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.AddUserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.AddUserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.AddUserData.displayName = 'proto.AimsunSF.v6.AddUserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.File.displayName = 'proto.AimsunSF.v6.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Billing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.Billing.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.Billing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Billing.displayName = 'proto.AimsunSF.v6.Billing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.Project.displayName = 'proto.AimsunSF.v6.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.StorageForProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.StorageForProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.StorageForProject.displayName = 'proto.AimsunSF.v6.StorageForProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.MyProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.MyProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.MyProject.displayName = 'proto.AimsunSF.v6.MyProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ProjectId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ProjectId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ProjectId.displayName = 'proto.AimsunSF.v6.ProjectId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ProjectUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.ProjectUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ProjectUser.displayName = 'proto.AimsunSF.v6.ProjectUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.ProjectInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AimsunSF.v6.ProjectInfo.repeatedFields_, null);
};
goog.inherits(proto.AimsunSF.v6.ProjectInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.ProjectInfo.displayName = 'proto.AimsunSF.v6.ProjectInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.CreateProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.CreateProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.CreateProject.displayName = 'proto.AimsunSF.v6.CreateProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.AddProjectUserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.AddProjectUserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.AddProjectUserData.displayName = 'proto.AimsunSF.v6.AddProjectUserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.RemoveProjectUserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.RemoveProjectUserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.RemoveProjectUserData.displayName = 'proto.AimsunSF.v6.RemoveProjectUserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AimsunSF.v6.UpdateProjectUserRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AimsunSF.v6.UpdateProjectUserRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AimsunSF.v6.UpdateProjectUserRole.displayName = 'proto.AimsunSF.v6.UpdateProjectUserRole';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    api: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backendversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    backendgithash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    backendbuilddate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Version}
 */
proto.AimsunSF.v6.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Version;
  return proto.AimsunSF.v6.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Version}
 */
proto.AimsunSF.v6.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendversion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendgithash(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendbuilddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBackendversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBackendgithash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBackendbuilddate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 api = 1;
 * @return {number}
 */
proto.AimsunSF.v6.Version.prototype.getApi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.Version} returns this
 */
proto.AimsunSF.v6.Version.prototype.setApi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string backendVersion = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Version.prototype.getBackendversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Version} returns this
 */
proto.AimsunSF.v6.Version.prototype.setBackendversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string backendGitHash = 3;
 * @return {string}
 */
proto.AimsunSF.v6.Version.prototype.getBackendgithash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Version} returns this
 */
proto.AimsunSF.v6.Version.prototype.setBackendgithash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string backendBuildDate = 4;
 * @return {string}
 */
proto.AimsunSF.v6.Version.prototype.getBackendbuilddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Version} returns this
 */
proto.AimsunSF.v6.Version.prototype.setBackendbuilddate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Email}
 */
proto.AimsunSF.v6.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Email;
  return proto.AimsunSF.v6.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Email}
 */
proto.AimsunSF.v6.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Email.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Email} returns this
 */
proto.AimsunSF.v6.Email.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.FederatedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.FederatedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.FederatedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.FederatedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    federated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.FederatedResponse}
 */
proto.AimsunSF.v6.FederatedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.FederatedResponse;
  return proto.AimsunSF.v6.FederatedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.FederatedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.FederatedResponse}
 */
proto.AimsunSF.v6.FederatedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFederated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.FederatedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.FederatedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.FederatedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.FederatedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFederated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool federated = 1;
 * @return {boolean}
 */
proto.AimsunSF.v6.FederatedResponse.prototype.getFederated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AimsunSF.v6.FederatedResponse} returns this
 */
proto.AimsunSF.v6.FederatedResponse.prototype.setFederated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.UserToken.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.UserToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.UserToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    usertoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.UserToken}
 */
proto.AimsunSF.v6.UserToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.UserToken;
  return proto.AimsunSF.v6.UserToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.UserToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.UserToken}
 */
proto.AimsunSF.v6.UserToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsertoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.UserToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.UserToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.UserToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsertoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userToken = 1;
 * @return {string}
 */
proto.AimsunSF.v6.UserToken.prototype.getUsertoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.UserToken} returns this
 */
proto.AimsunSF.v6.UserToken.prototype.setUsertoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ActivationData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ActivationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ActivationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ActivationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rightid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ActivationData}
 */
proto.AimsunSF.v6.ActivationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ActivationData;
  return proto.AimsunSF.v6.ActivationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ActivationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ActivationData}
 */
proto.AimsunSF.v6.ActivationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ActivationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ActivationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ActivationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ActivationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string machineId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ActivationData.prototype.getMachineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ActivationData} returns this
 */
proto.AimsunSF.v6.ActivationData.prototype.setMachineid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rightId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ActivationData.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ActivationData} returns this
 */
proto.AimsunSF.v6.ActivationData.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 duration = 3;
 * @return {number}
 */
proto.AimsunSF.v6.ActivationData.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.ActivationData} returns this
 */
proto.AimsunSF.v6.ActivationData.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ActivationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ActivationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ActivationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ActivationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    license: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rightactivationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extradata: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ActivationResponse}
 */
proto.AimsunSF.v6.ActivationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ActivationResponse;
  return proto.AimsunSF.v6.ActivationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ActivationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ActivationResponse}
 */
proto.AimsunSF.v6.ActivationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightactivationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtradata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ActivationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ActivationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ActivationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ActivationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRightactivationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtradata();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string license = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ActivationResponse.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ActivationResponse} returns this
 */
proto.AimsunSF.v6.ActivationResponse.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rightActivationId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ActivationResponse.prototype.getRightactivationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ActivationResponse} returns this
 */
proto.AimsunSF.v6.ActivationResponse.prototype.setRightactivationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string extraData = 3;
 * @return {string}
 */
proto.AimsunSF.v6.ActivationResponse.prototype.getExtradata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ActivationResponse} returns this
 */
proto.AimsunSF.v6.ActivationResponse.prototype.setExtradata = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ExtendData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ExtendData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ExtendData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ExtendData.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rightid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rightactivationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ExtendData}
 */
proto.AimsunSF.v6.ExtendData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ExtendData;
  return proto.AimsunSF.v6.ExtendData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ExtendData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ExtendData}
 */
proto.AimsunSF.v6.ExtendData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightactivationid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ExtendData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ExtendData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ExtendData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ExtendData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRightactivationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string machineId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ExtendData.prototype.getMachineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ExtendData} returns this
 */
proto.AimsunSF.v6.ExtendData.prototype.setMachineid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rightId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ExtendData.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ExtendData} returns this
 */
proto.AimsunSF.v6.ExtendData.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rightActivationId = 3;
 * @return {string}
 */
proto.AimsunSF.v6.ExtendData.prototype.getRightactivationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ExtendData} returns this
 */
proto.AimsunSF.v6.ExtendData.prototype.setRightactivationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keyId = 4;
 * @return {string}
 */
proto.AimsunSF.v6.ExtendData.prototype.getKeyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ExtendData} returns this
 */
proto.AimsunSF.v6.ExtendData.prototype.setKeyid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ReleaseData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ReleaseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ReleaseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    licensedata: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keyid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ReleaseData}
 */
proto.AimsunSF.v6.ReleaseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ReleaseData;
  return proto.AimsunSF.v6.ReleaseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ReleaseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ReleaseData}
 */
proto.AimsunSF.v6.ReleaseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensedata(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ReleaseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ReleaseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ReleaseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicensedata();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string licenseData = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ReleaseData.prototype.getLicensedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ReleaseData} returns this
 */
proto.AimsunSF.v6.ReleaseData.prototype.setLicensedata = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ReleaseData.prototype.getKeyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ReleaseData} returns this
 */
proto.AimsunSF.v6.ReleaseData.prototype.setKeyid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ReleaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ReleaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ReleaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ack: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ReleaseResponse}
 */
proto.AimsunSF.v6.ReleaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ReleaseResponse;
  return proto.AimsunSF.v6.ReleaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ReleaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ReleaseResponse}
 */
proto.AimsunSF.v6.ReleaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ReleaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ReleaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ReleaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAck();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ACK = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ReleaseResponse.prototype.getAck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ReleaseResponse} returns this
 */
proto.AimsunSF.v6.ReleaseResponse.prototype.setAck = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.InUseActivationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.InUseActivationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.InUseActivationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expirationdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hostname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.InUseActivationInfo}
 */
proto.AimsunSF.v6.InUseActivationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.InUseActivationInfo;
  return proto.AimsunSF.v6.InUseActivationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.InUseActivationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.InUseActivationInfo}
 */
proto.AimsunSF.v6.InUseActivationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.InUseActivationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.InUseActivationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.InUseActivationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpirationdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.InUseActivationInfo} returns this
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.InUseActivationInfo} returns this
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expirationDate = 3;
 * @return {string}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.getExpirationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.InUseActivationInfo} returns this
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.setExpirationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hostName = 4;
 * @return {string}
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.InUseActivationInfo} returns this
 */
proto.AimsunSF.v6.InUseActivationInfo.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.RightInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.RightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.RightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.RightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    righttype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxactivations: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expirationdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    usedactivationsList: jspb.Message.toObjectList(msg.getUsedactivationsList(),
    proto.AimsunSF.v6.InUseActivationInfo.toObject, includeInstance),
    userid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detachduration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    usertokenstatus: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.RightInfo}
 */
proto.AimsunSF.v6.RightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.RightInfo;
  return proto.AimsunSF.v6.RightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.RightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.RightInfo}
 */
proto.AimsunSF.v6.RightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRighttype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxactivations(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationdate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 6:
      var value = new proto.AimsunSF.v6.InUseActivationInfo;
      reader.readMessage(value,proto.AimsunSF.v6.InUseActivationInfo.deserializeBinaryFromReader);
      msg.addUsedactivations(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDetachduration(value);
      break;
    case 9:
      var value = /** @type {!proto.AimsunSF.v6.UserTokenStatus} */ (reader.readEnum());
      msg.setUsertokenstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.RightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.RightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.RightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRighttype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxactivations();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExpirationdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUsedactivationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.AimsunSF.v6.InUseActivationInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetachduration();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUsertokenstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string rightId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.RightInfo.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rightType = 2;
 * @return {number}
 */
proto.AimsunSF.v6.RightInfo.prototype.getRighttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setRighttype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 maxActivations = 3;
 * @return {number}
 */
proto.AimsunSF.v6.RightInfo.prototype.getMaxactivations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setMaxactivations = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string expirationDate = 4;
 * @return {string}
 */
proto.AimsunSF.v6.RightInfo.prototype.getExpirationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setExpirationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool expired = 5;
 * @return {boolean}
 */
proto.AimsunSF.v6.RightInfo.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated InUseActivationInfo usedActivations = 6;
 * @return {!Array<!proto.AimsunSF.v6.InUseActivationInfo>}
 */
proto.AimsunSF.v6.RightInfo.prototype.getUsedactivationsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.InUseActivationInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.InUseActivationInfo, 6));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.InUseActivationInfo>} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
*/
proto.AimsunSF.v6.RightInfo.prototype.setUsedactivationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.AimsunSF.v6.InUseActivationInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.InUseActivationInfo}
 */
proto.AimsunSF.v6.RightInfo.prototype.addUsedactivations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.AimsunSF.v6.InUseActivationInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.clearUsedactivationsList = function() {
  return this.setUsedactivationsList([]);
};


/**
 * optional string userId = 7;
 * @return {string}
 */
proto.AimsunSF.v6.RightInfo.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 detachDuration = 8;
 * @return {number}
 */
proto.AimsunSF.v6.RightInfo.prototype.getDetachduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setDetachduration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional UserTokenStatus userTokenStatus = 9;
 * @return {!proto.AimsunSF.v6.UserTokenStatus}
 */
proto.AimsunSF.v6.RightInfo.prototype.getUsertokenstatus = function() {
  return /** @type {!proto.AimsunSF.v6.UserTokenStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.AimsunSF.v6.UserTokenStatus} value
 * @return {!proto.AimsunSF.v6.RightInfo} returns this
 */
proto.AimsunSF.v6.RightInfo.prototype.setUsertokenstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.RightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.RightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.RightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.RightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightsList: jspb.Message.toObjectList(msg.getRightsList(),
    proto.AimsunSF.v6.RightInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.RightsResponse}
 */
proto.AimsunSF.v6.RightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.RightsResponse;
  return proto.AimsunSF.v6.RightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.RightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.RightsResponse}
 */
proto.AimsunSF.v6.RightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.RightInfo;
      reader.readMessage(value,proto.AimsunSF.v6.RightInfo.deserializeBinaryFromReader);
      msg.addRights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.RightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.RightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.RightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AimsunSF.v6.RightInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RightInfo rights = 1;
 * @return {!Array<!proto.AimsunSF.v6.RightInfo>}
 */
proto.AimsunSF.v6.RightsResponse.prototype.getRightsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.RightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.RightInfo, 1));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.RightInfo>} value
 * @return {!proto.AimsunSF.v6.RightsResponse} returns this
*/
proto.AimsunSF.v6.RightsResponse.prototype.setRightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AimsunSF.v6.RightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.RightInfo}
 */
proto.AimsunSF.v6.RightsResponse.prototype.addRights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AimsunSF.v6.RightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.RightsResponse} returns this
 */
proto.AimsunSF.v6.RightsResponse.prototype.clearRightsList = function() {
  return this.setRightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ACK.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ACK.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ACK} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ACK.toObject = function(includeInstance, msg) {
  var f, obj = {
    ack: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rightactivationid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ACK}
 */
proto.AimsunSF.v6.ACK.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ACK;
  return proto.AimsunSF.v6.ACK.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ACK} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ACK}
 */
proto.AimsunSF.v6.ACK.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAck(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightactivationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ACK.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ACK.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ACK} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ACK.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAck();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRightactivationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ACK = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ACK.prototype.getAck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ACK} returns this
 */
proto.AimsunSF.v6.ACK.prototype.setAck = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ACK.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ACK} returns this
 */
proto.AimsunSF.v6.ACK.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rightActivationId = 3;
 * @return {string}
 */
proto.AimsunSF.v6.ACK.prototype.getRightactivationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ACK} returns this
 */
proto.AimsunSF.v6.ACK.prototype.setRightactivationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.TokenForRight.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.TokenForRight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.TokenForRight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.TokenForRight.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.TokenForRight}
 */
proto.AimsunSF.v6.TokenForRight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.TokenForRight;
  return proto.AimsunSF.v6.TokenForRight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.TokenForRight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.TokenForRight}
 */
proto.AimsunSF.v6.TokenForRight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.TokenForRight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.TokenForRight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.TokenForRight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.TokenForRight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string rightId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.TokenForRight.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.TokenForRight} returns this
 */
proto.AimsunSF.v6.TokenForRight.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.AssignRightData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.AssignRightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.AssignRightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AssignRightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.AssignRightData}
 */
proto.AimsunSF.v6.AssignRightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.AssignRightData;
  return proto.AimsunSF.v6.AssignRightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.AssignRightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.AssignRightData}
 */
proto.AimsunSF.v6.AssignRightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.AssignRightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.AssignRightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.AssignRightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AssignRightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rightId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.AssignRightData.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AssignRightData} returns this
 */
proto.AimsunSF.v6.AssignRightData.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.AssignRightData.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AssignRightData} returns this
 */
proto.AimsunSF.v6.AssignRightData.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ReleaseRightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ReleaseRightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseRightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ReleaseRightData}
 */
proto.AimsunSF.v6.ReleaseRightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ReleaseRightData;
  return proto.AimsunSF.v6.ReleaseRightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ReleaseRightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ReleaseRightData}
 */
proto.AimsunSF.v6.ReleaseRightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ReleaseRightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ReleaseRightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ReleaseRightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rightId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.getRightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ReleaseRightData} returns this
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.setRightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ReleaseRightData} returns this
 */
proto.AimsunSF.v6.ReleaseRightData.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    province: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countrycode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Address}
 */
proto.AimsunSF.v6.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Address;
  return proto.AimsunSF.v6.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Address}
 */
proto.AimsunSF.v6.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string postalCode = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string province = 4;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string countryCode = 6;
 * @return {string}
 */
proto.AimsunSF.v6.Address.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Address} returns this
 */
proto.AimsunSF.v6.Address.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.AccountProfile.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.AccountProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.AccountProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.AccountProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AccountProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.AccountProfile}
 */
proto.AimsunSF.v6.AccountProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.AccountProfile;
  return proto.AimsunSF.v6.AccountProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.AccountProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.AccountProfile}
 */
proto.AimsunSF.v6.AccountProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.AccountProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.AccountProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.AccountProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AccountProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string domains = 1;
 * @return {!Array<string>}
 */
proto.AimsunSF.v6.AccountProfile.prototype.getDomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.AimsunSF.v6.AccountProfile} returns this
 */
proto.AimsunSF.v6.AccountProfile.prototype.setDomainsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.AccountProfile} returns this
 */
proto.AimsunSF.v6.AccountProfile.prototype.addDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.AccountProfile} returns this
 */
proto.AimsunSF.v6.AccountProfile.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cognitoid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    address: (f = msg.getAddress()) && proto.AimsunSF.v6.Address.toObject(includeInstance, f),
    accountid: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Profile}
 */
proto.AimsunSF.v6.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Profile;
  return proto.AimsunSF.v6.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Profile}
 */
proto.AimsunSF.v6.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCognitoid(value);
      break;
    case 7:
      var value = /** @type {!proto.AimsunSF.v6.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 8:
      var value = new proto.AimsunSF.v6.Address;
      reader.readMessage(value,proto.AimsunSF.v6.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCognitoid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.AimsunSF.v6.Address.serializeBinaryToWriter
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string salutation = 3;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string firstName = 4;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastName = 5;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cognitoId = 6;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getCognitoid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setCognitoid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserType userType = 7;
 * @return {!proto.AimsunSF.v6.UserType}
 */
proto.AimsunSF.v6.Profile.prototype.getUsertype = function() {
  return /** @type {!proto.AimsunSF.v6.UserType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.AimsunSF.v6.UserType} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Address address = 8;
 * @return {?proto.AimsunSF.v6.Address}
 */
proto.AimsunSF.v6.Profile.prototype.getAddress = function() {
  return /** @type{?proto.AimsunSF.v6.Address} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Address, 8));
};


/**
 * @param {?proto.AimsunSF.v6.Address|undefined} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
*/
proto.AimsunSF.v6.Profile.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.Profile.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string accountId = 9;
 * @return {string}
 */
proto.AimsunSF.v6.Profile.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Profile} returns this
 */
proto.AimsunSF.v6.Profile.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.UserInfo.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.AimsunSF.v6.Profile.toObject(includeInstance, f),
    rightsList: jspb.Message.toObjectList(msg.getRightsList(),
    proto.AimsunSF.v6.RightInfo.toObject, includeInstance),
    seatassigmentsList: jspb.Message.toObjectList(msg.getSeatassigmentsList(),
    proto.AimsunSF.v6.SeatAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.UserInfo}
 */
proto.AimsunSF.v6.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.UserInfo;
  return proto.AimsunSF.v6.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.UserInfo}
 */
proto.AimsunSF.v6.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.Profile;
      reader.readMessage(value,proto.AimsunSF.v6.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = new proto.AimsunSF.v6.RightInfo;
      reader.readMessage(value,proto.AimsunSF.v6.RightInfo.deserializeBinaryFromReader);
      msg.addRights(value);
      break;
    case 3:
      var value = new proto.AimsunSF.v6.SeatAssignment;
      reader.readMessage(value,proto.AimsunSF.v6.SeatAssignment.deserializeBinaryFromReader);
      msg.addSeatassigments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AimsunSF.v6.Profile.serializeBinaryToWriter
    );
  }
  f = message.getRightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.AimsunSF.v6.RightInfo.serializeBinaryToWriter
    );
  }
  f = message.getSeatassigmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.AimsunSF.v6.SeatAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Profile profile = 1;
 * @return {?proto.AimsunSF.v6.Profile}
 */
proto.AimsunSF.v6.UserInfo.prototype.getProfile = function() {
  return /** @type{?proto.AimsunSF.v6.Profile} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Profile, 1));
};


/**
 * @param {?proto.AimsunSF.v6.Profile|undefined} value
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
*/
proto.AimsunSF.v6.UserInfo.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
 */
proto.AimsunSF.v6.UserInfo.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.UserInfo.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RightInfo rights = 2;
 * @return {!Array<!proto.AimsunSF.v6.RightInfo>}
 */
proto.AimsunSF.v6.UserInfo.prototype.getRightsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.RightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.RightInfo, 2));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.RightInfo>} value
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
*/
proto.AimsunSF.v6.UserInfo.prototype.setRightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.AimsunSF.v6.RightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.RightInfo}
 */
proto.AimsunSF.v6.UserInfo.prototype.addRights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.AimsunSF.v6.RightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
 */
proto.AimsunSF.v6.UserInfo.prototype.clearRightsList = function() {
  return this.setRightsList([]);
};


/**
 * repeated SeatAssignment seatAssigments = 3;
 * @return {!Array<!proto.AimsunSF.v6.SeatAssignment>}
 */
proto.AimsunSF.v6.UserInfo.prototype.getSeatassigmentsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.SeatAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.SeatAssignment, 3));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.SeatAssignment>} value
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
*/
proto.AimsunSF.v6.UserInfo.prototype.setSeatassigmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.AimsunSF.v6.SeatAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.SeatAssignment}
 */
proto.AimsunSF.v6.UserInfo.prototype.addSeatassigments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.AimsunSF.v6.SeatAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.UserInfo} returns this
 */
proto.AimsunSF.v6.UserInfo.prototype.clearSeatassigmentsList = function() {
  return this.setSeatassigmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.UserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userinfoList: jspb.Message.toObjectList(msg.getUserinfoList(),
    proto.AimsunSF.v6.UserInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.UserResponse}
 */
proto.AimsunSF.v6.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.UserResponse;
  return proto.AimsunSF.v6.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.UserResponse}
 */
proto.AimsunSF.v6.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.UserInfo;
      reader.readMessage(value,proto.AimsunSF.v6.UserInfo.deserializeBinaryFromReader);
      msg.addUserinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AimsunSF.v6.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserInfo userInfo = 1;
 * @return {!Array<!proto.AimsunSF.v6.UserInfo>}
 */
proto.AimsunSF.v6.UserResponse.prototype.getUserinfoList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.UserInfo, 1));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.UserInfo>} value
 * @return {!proto.AimsunSF.v6.UserResponse} returns this
*/
proto.AimsunSF.v6.UserResponse.prototype.setUserinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AimsunSF.v6.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.UserInfo}
 */
proto.AimsunSF.v6.UserResponse.prototype.addUserinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AimsunSF.v6.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.UserResponse} returns this
 */
proto.AimsunSF.v6.UserResponse.prototype.clearUserinfoList = function() {
  return this.setUserinfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ChangeUserTypeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ChangeUserTypeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ChangeUserTypeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ChangeUserTypeData}
 */
proto.AimsunSF.v6.ChangeUserTypeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ChangeUserTypeData;
  return proto.AimsunSF.v6.ChangeUserTypeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ChangeUserTypeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ChangeUserTypeData}
 */
proto.AimsunSF.v6.ChangeUserTypeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.AimsunSF.v6.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ChangeUserTypeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ChangeUserTypeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ChangeUserTypeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ChangeUserTypeData} returns this
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserType userType = 2;
 * @return {!proto.AimsunSF.v6.UserType}
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.getUsertype = function() {
  return /** @type {!proto.AimsunSF.v6.UserType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.AimsunSF.v6.UserType} value
 * @return {!proto.AimsunSF.v6.ChangeUserTypeData} returns this
 */
proto.AimsunSF.v6.ChangeUserTypeData.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.UserId.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.UserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.UserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.UserId}
 */
proto.AimsunSF.v6.UserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.UserId;
  return proto.AimsunSF.v6.UserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.UserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.UserId}
 */
proto.AimsunSF.v6.UserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.UserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.UserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.UserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.UserId.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.UserId} returns this
 */
proto.AimsunSF.v6.UserId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Product}
 */
proto.AimsunSF.v6.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Product;
  return proto.AimsunSF.v6.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Product}
 */
proto.AimsunSF.v6.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Product} returns this
 */
proto.AimsunSF.v6.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Product} returns this
 */
proto.AimsunSF.v6.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.SubscriptionLine.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.SubscriptionLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.SubscriptionLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SubscriptionLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxactivations: jspb.Message.getFieldWithDefault(msg, 4, 0),
    detachduration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    product: (f = msg.getProduct()) && proto.AimsunSF.v6.Product.toObject(includeInstance, f),
    optionalproductsList: jspb.Message.toObjectList(msg.getOptionalproductsList(),
    proto.AimsunSF.v6.Product.toObject, includeInstance),
    expirationdate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    usedseats: jspb.Message.getFieldWithDefault(msg, 9, 0),
    licensetype: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.SubscriptionLine}
 */
proto.AimsunSF.v6.SubscriptionLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.SubscriptionLine;
  return proto.AimsunSF.v6.SubscriptionLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.SubscriptionLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.SubscriptionLine}
 */
proto.AimsunSF.v6.SubscriptionLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxactivations(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDetachduration(value);
      break;
    case 6:
      var value = new proto.AimsunSF.v6.Product;
      reader.readMessage(value,proto.AimsunSF.v6.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 7:
      var value = new proto.AimsunSF.v6.Product;
      reader.readMessage(value,proto.AimsunSF.v6.Product.deserializeBinaryFromReader);
      msg.addOptionalproducts(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationdate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsedseats(value);
      break;
    case 10:
      var value = /** @type {!proto.AimsunSF.v6.LicenseType} */ (reader.readEnum());
      msg.setLicensetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.SubscriptionLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.SubscriptionLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SubscriptionLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxactivations();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDetachduration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.AimsunSF.v6.Product.serializeBinaryToWriter
    );
  }
  f = message.getOptionalproductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.AimsunSF.v6.Product.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUsedseats();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLicensetype();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 seats = 3;
 * @return {number}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 maxActivations = 4;
 * @return {number}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getMaxactivations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setMaxactivations = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 detachDuration = 5;
 * @return {number}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getDetachduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setDetachduration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Product product = 6;
 * @return {?proto.AimsunSF.v6.Product}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getProduct = function() {
  return /** @type{?proto.AimsunSF.v6.Product} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Product, 6));
};


/**
 * @param {?proto.AimsunSF.v6.Product|undefined} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
*/
proto.AimsunSF.v6.SubscriptionLine.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Product optionalProducts = 7;
 * @return {!Array<!proto.AimsunSF.v6.Product>}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getOptionalproductsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.Product, 7));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.Product>} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
*/
proto.AimsunSF.v6.SubscriptionLine.prototype.setOptionalproductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.AimsunSF.v6.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.Product}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.addOptionalproducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.AimsunSF.v6.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.clearOptionalproductsList = function() {
  return this.setOptionalproductsList([]);
};


/**
 * optional string expirationDate = 8;
 * @return {string}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getExpirationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setExpirationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 usedSeats = 9;
 * @return {number}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getUsedseats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setUsedseats = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional LicenseType licenseType = 10;
 * @return {!proto.AimsunSF.v6.LicenseType}
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.getLicensetype = function() {
  return /** @type {!proto.AimsunSF.v6.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.AimsunSF.v6.LicenseType} value
 * @return {!proto.AimsunSF.v6.SubscriptionLine} returns this
 */
proto.AimsunSF.v6.SubscriptionLine.prototype.setLicensetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.Subscription.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    todate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.AimsunSF.v6.SubscriptionLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Subscription}
 */
proto.AimsunSF.v6.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Subscription;
  return proto.AimsunSF.v6.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Subscription}
 */
proto.AimsunSF.v6.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractid(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 7:
      var value = new proto.AimsunSF.v6.SubscriptionLine;
      reader.readMessage(value,proto.AimsunSF.v6.SubscriptionLine.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTodate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.AimsunSF.v6.SubscriptionLine.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Subscription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fromDate = 3;
 * @return {string}
 */
proto.AimsunSF.v6.Subscription.prototype.getFromdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setFromdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string toDate = 4;
 * @return {string}
 */
proto.AimsunSF.v6.Subscription.prototype.getTodate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setTodate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contractId = 5;
 * @return {string}
 */
proto.AimsunSF.v6.Subscription.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool expired = 6;
 * @return {boolean}
 */
proto.AimsunSF.v6.Subscription.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated SubscriptionLine lines = 7;
 * @return {!Array<!proto.AimsunSF.v6.SubscriptionLine>}
 */
proto.AimsunSF.v6.Subscription.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.SubscriptionLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.SubscriptionLine, 7));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.SubscriptionLine>} value
 * @return {!proto.AimsunSF.v6.Subscription} returns this
*/
proto.AimsunSF.v6.Subscription.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.AimsunSF.v6.SubscriptionLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.SubscriptionLine}
 */
proto.AimsunSF.v6.Subscription.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.AimsunSF.v6.SubscriptionLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.Subscription} returns this
 */
proto.AimsunSF.v6.Subscription.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.SubscriptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.SubscriptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.SubscriptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.SubscriptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SubscriptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionList: jspb.Message.toObjectList(msg.getSubscriptionList(),
    proto.AimsunSF.v6.Subscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.SubscriptionsResponse}
 */
proto.AimsunSF.v6.SubscriptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.SubscriptionsResponse;
  return proto.AimsunSF.v6.SubscriptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.SubscriptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.SubscriptionsResponse}
 */
proto.AimsunSF.v6.SubscriptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.Subscription;
      reader.readMessage(value,proto.AimsunSF.v6.Subscription.deserializeBinaryFromReader);
      msg.addSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.SubscriptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.SubscriptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.SubscriptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SubscriptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AimsunSF.v6.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Subscription subscription = 1;
 * @return {!Array<!proto.AimsunSF.v6.Subscription>}
 */
proto.AimsunSF.v6.SubscriptionsResponse.prototype.getSubscriptionList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.Subscription, 1));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.Subscription>} value
 * @return {!proto.AimsunSF.v6.SubscriptionsResponse} returns this
*/
proto.AimsunSF.v6.SubscriptionsResponse.prototype.setSubscriptionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AimsunSF.v6.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.Subscription}
 */
proto.AimsunSF.v6.SubscriptionsResponse.prototype.addSubscription = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AimsunSF.v6.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.SubscriptionsResponse} returns this
 */
proto.AimsunSF.v6.SubscriptionsResponse.prototype.clearSubscriptionList = function() {
  return this.setSubscriptionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.SeatAssignment.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.SeatAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.SeatAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SeatAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionline: (f = msg.getSubscriptionline()) && proto.AimsunSF.v6.SubscriptionLine.toObject(includeInstance, f),
    fromdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    todate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expirationdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxactivations: jspb.Message.getFieldWithDefault(msg, 6, 0),
    detachduration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    usedactivationsList: jspb.Message.toObjectList(msg.getUsedactivationsList(),
    proto.AimsunSF.v6.InUseActivationInfo.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 9, 0),
    usertokenstatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    licensetype: jspb.Message.getFieldWithDefault(msg, 11, 0),
    numberofseats: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.SeatAssignment}
 */
proto.AimsunSF.v6.SeatAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.SeatAssignment;
  return proto.AimsunSF.v6.SeatAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.SeatAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.SeatAssignment}
 */
proto.AimsunSF.v6.SeatAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.AimsunSF.v6.SubscriptionLine;
      reader.readMessage(value,proto.AimsunSF.v6.SubscriptionLine.deserializeBinaryFromReader);
      msg.setSubscriptionline(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationdate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxactivations(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDetachduration(value);
      break;
    case 8:
      var value = new proto.AimsunSF.v6.InUseActivationInfo;
      reader.readMessage(value,proto.AimsunSF.v6.InUseActivationInfo.deserializeBinaryFromReader);
      msg.addUsedactivations(value);
      break;
    case 9:
      var value = /** @type {!proto.AimsunSF.v6.SeatAssignmentState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {!proto.AimsunSF.v6.UserTokenStatus} */ (reader.readEnum());
      msg.setUsertokenstatus(value);
      break;
    case 11:
      var value = /** @type {!proto.AimsunSF.v6.LicenseType} */ (reader.readEnum());
      msg.setLicensetype(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberofseats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.SeatAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.SeatAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SeatAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionline();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AimsunSF.v6.SubscriptionLine.serializeBinaryToWriter
    );
  }
  f = message.getFromdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTodate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpirationdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxactivations();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDetachduration();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUsedactivationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.AimsunSF.v6.InUseActivationInfo.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getUsertokenstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLicensetype();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getNumberofseats();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SubscriptionLine subscriptionLine = 2;
 * @return {?proto.AimsunSF.v6.SubscriptionLine}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getSubscriptionline = function() {
  return /** @type{?proto.AimsunSF.v6.SubscriptionLine} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.SubscriptionLine, 2));
};


/**
 * @param {?proto.AimsunSF.v6.SubscriptionLine|undefined} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
*/
proto.AimsunSF.v6.SeatAssignment.prototype.setSubscriptionline = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.clearSubscriptionline = function() {
  return this.setSubscriptionline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.hasSubscriptionline = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string fromDate = 3;
 * @return {string}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getFromdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setFromdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string toDate = 4;
 * @return {string}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getTodate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setTodate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string expirationDate = 5;
 * @return {string}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getExpirationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setExpirationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 maxActivations = 6;
 * @return {number}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getMaxactivations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setMaxactivations = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 detachDuration = 7;
 * @return {number}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getDetachduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setDetachduration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated InUseActivationInfo usedActivations = 8;
 * @return {!Array<!proto.AimsunSF.v6.InUseActivationInfo>}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getUsedactivationsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.InUseActivationInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.InUseActivationInfo, 8));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.InUseActivationInfo>} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
*/
proto.AimsunSF.v6.SeatAssignment.prototype.setUsedactivationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.AimsunSF.v6.InUseActivationInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.InUseActivationInfo}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.addUsedactivations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.AimsunSF.v6.InUseActivationInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.clearUsedactivationsList = function() {
  return this.setUsedactivationsList([]);
};


/**
 * optional SeatAssignmentState state = 9;
 * @return {!proto.AimsunSF.v6.SeatAssignmentState}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getState = function() {
  return /** @type {!proto.AimsunSF.v6.SeatAssignmentState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.AimsunSF.v6.SeatAssignmentState} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional UserTokenStatus userTokenStatus = 10;
 * @return {!proto.AimsunSF.v6.UserTokenStatus}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getUsertokenstatus = function() {
  return /** @type {!proto.AimsunSF.v6.UserTokenStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.AimsunSF.v6.UserTokenStatus} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setUsertokenstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional LicenseType licenseType = 11;
 * @return {!proto.AimsunSF.v6.LicenseType}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getLicensetype = function() {
  return /** @type {!proto.AimsunSF.v6.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.AimsunSF.v6.LicenseType} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setLicensetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 numberOfSeats = 12;
 * @return {number}
 */
proto.AimsunSF.v6.SeatAssignment.prototype.getNumberofseats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.SeatAssignment} returns this
 */
proto.AimsunSF.v6.SeatAssignment.prototype.setNumberofseats = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.SeatAssignmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.SeatAssignmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.AimsunSF.v6.SeatAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.SeatAssignmentsResponse}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.SeatAssignmentsResponse;
  return proto.AimsunSF.v6.SeatAssignmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.SeatAssignmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.SeatAssignmentsResponse}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.SeatAssignment;
      reader.readMessage(value,proto.AimsunSF.v6.SeatAssignment.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.SeatAssignmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.SeatAssignmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AimsunSF.v6.SeatAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SeatAssignment seats = 1;
 * @return {!Array<!proto.AimsunSF.v6.SeatAssignment>}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.SeatAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.SeatAssignment, 1));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.SeatAssignment>} value
 * @return {!proto.AimsunSF.v6.SeatAssignmentsResponse} returns this
*/
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AimsunSF.v6.SeatAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.SeatAssignment}
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AimsunSF.v6.SeatAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.SeatAssignmentsResponse} returns this
 */
proto.AimsunSF.v6.SeatAssignmentsResponse.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.AssignSeatData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.AssignSeatData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.AssignSeatData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AssignSeatData.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionlineid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberofseats: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.AssignSeatData}
 */
proto.AimsunSF.v6.AssignSeatData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.AssignSeatData;
  return proto.AimsunSF.v6.AssignSeatData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.AssignSeatData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.AssignSeatData}
 */
proto.AimsunSF.v6.AssignSeatData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionlineid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberofseats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.AssignSeatData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.AssignSeatData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.AssignSeatData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AssignSeatData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionlineid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberofseats();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.AssignSeatData.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AssignSeatData} returns this
 */
proto.AimsunSF.v6.AssignSeatData.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscriptionLineId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.AssignSeatData.prototype.getSubscriptionlineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AssignSeatData} returns this
 */
proto.AimsunSF.v6.AssignSeatData.prototype.setSubscriptionlineid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 numberOfSeats = 3;
 * @return {number}
 */
proto.AimsunSF.v6.AssignSeatData.prototype.getNumberofseats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AimsunSF.v6.AssignSeatData} returns this
 */
proto.AimsunSF.v6.AssignSeatData.prototype.setNumberofseats = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.AddUserData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.AddUserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.AddUserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AddUserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.AddUserData}
 */
proto.AimsunSF.v6.AddUserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.AddUserData;
  return proto.AimsunSF.v6.AddUserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.AddUserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.AddUserData}
 */
proto.AimsunSF.v6.AddUserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.AddUserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.AddUserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.AddUserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AddUserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.AimsunSF.v6.AddUserData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddUserData} returns this
 */
proto.AimsunSF.v6.AddUserData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string salutation = 2;
 * @return {string}
 */
proto.AimsunSF.v6.AddUserData.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddUserData} returns this
 */
proto.AimsunSF.v6.AddUserData.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.AimsunSF.v6.AddUserData.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddUserData} returns this
 */
proto.AimsunSF.v6.AddUserData.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.AimsunSF.v6.AddUserData.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddUserData} returns this
 */
proto.AimsunSF.v6.AddUserData.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.File.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.File}
 */
proto.AimsunSF.v6.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.File;
  return proto.AimsunSF.v6.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.File}
 */
proto.AimsunSF.v6.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AimsunSF.v6.FileType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional FileType type = 1;
 * @return {!proto.AimsunSF.v6.FileType}
 */
proto.AimsunSF.v6.File.prototype.getType = function() {
  return /** @type {!proto.AimsunSF.v6.FileType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AimsunSF.v6.FileType} value
 * @return {!proto.AimsunSF.v6.File} returns this
 */
proto.AimsunSF.v6.File.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.AimsunSF.v6.File.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.File} returns this
 */
proto.AimsunSF.v6.File.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.AimsunSF.v6.File.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.File} returns this
 */
proto.AimsunSF.v6.File.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.AimsunSF.v6.File.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.File} returns this
 */
proto.AimsunSF.v6.File.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.AimsunSF.v6.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.File} returns this
 */
proto.AimsunSF.v6.File.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.Billing.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Billing.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Billing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Billing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Billing.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.AimsunSF.v6.Address.toObject(includeInstance, f),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.AimsunSF.v6.File.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Billing}
 */
proto.AimsunSF.v6.Billing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Billing;
  return proto.AimsunSF.v6.Billing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Billing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Billing}
 */
proto.AimsunSF.v6.Billing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 3:
      var value = new proto.AimsunSF.v6.Address;
      reader.readMessage(value,proto.AimsunSF.v6.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = new proto.AimsunSF.v6.File;
      reader.readMessage(value,proto.AimsunSF.v6.File.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Billing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Billing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Billing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Billing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.AimsunSF.v6.Address.serializeBinaryToWriter
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.AimsunSF.v6.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Billing.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Billing} returns this
 */
proto.AimsunSF.v6.Billing.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string taxId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Billing.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Billing} returns this
 */
proto.AimsunSF.v6.Billing.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address address = 3;
 * @return {?proto.AimsunSF.v6.Address}
 */
proto.AimsunSF.v6.Billing.prototype.getAddress = function() {
  return /** @type{?proto.AimsunSF.v6.Address} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Address, 3));
};


/**
 * @param {?proto.AimsunSF.v6.Address|undefined} value
 * @return {!proto.AimsunSF.v6.Billing} returns this
*/
proto.AimsunSF.v6.Billing.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.Billing} returns this
 */
proto.AimsunSF.v6.Billing.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.Billing.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated File documents = 4;
 * @return {!Array<!proto.AimsunSF.v6.File>}
 */
proto.AimsunSF.v6.Billing.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.File, 4));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.File>} value
 * @return {!proto.AimsunSF.v6.Billing} returns this
*/
proto.AimsunSF.v6.Billing.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.AimsunSF.v6.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.File}
 */
proto.AimsunSF.v6.Billing.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.AimsunSF.v6.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.Billing} returns this
 */
proto.AimsunSF.v6.Billing.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hasstorage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.Project}
 */
proto.AimsunSF.v6.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.Project;
  return proto.AimsunSF.v6.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.Project}
 */
proto.AimsunSF.v6.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasstorage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHasstorage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.Project.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Project} returns this
 */
proto.AimsunSF.v6.Project.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.AimsunSF.v6.Project.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Project} returns this
 */
proto.AimsunSF.v6.Project.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.AimsunSF.v6.Project.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Project} returns this
 */
proto.AimsunSF.v6.Project.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.AimsunSF.v6.Project.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.Project} returns this
 */
proto.AimsunSF.v6.Project.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool hasStorage = 5;
 * @return {boolean}
 */
proto.AimsunSF.v6.Project.prototype.getHasstorage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AimsunSF.v6.Project} returns this
 */
proto.AimsunSF.v6.Project.prototype.setHasstorage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.StorageForProject.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.StorageForProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.StorageForProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.StorageForProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storagelogin: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.StorageForProject}
 */
proto.AimsunSF.v6.StorageForProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.StorageForProject;
  return proto.AimsunSF.v6.StorageForProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.StorageForProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.StorageForProject}
 */
proto.AimsunSF.v6.StorageForProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoragelogin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.StorageForProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.StorageForProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.StorageForProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.StorageForProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStoragelogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.StorageForProject.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.StorageForProject} returns this
 */
proto.AimsunSF.v6.StorageForProject.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string storageLogin = 2;
 * @return {string}
 */
proto.AimsunSF.v6.StorageForProject.prototype.getStoragelogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.StorageForProject} returns this
 */
proto.AimsunSF.v6.StorageForProject.prototype.setStoragelogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.MyProject.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.MyProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.MyProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.MyProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    project: (f = msg.getProject()) && proto.AimsunSF.v6.Project.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.MyProject}
 */
proto.AimsunSF.v6.MyProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.MyProject;
  return proto.AimsunSF.v6.MyProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.MyProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.MyProject}
 */
proto.AimsunSF.v6.MyProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AimsunSF.v6.ProjectRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.AimsunSF.v6.Project;
      reader.readMessage(value,proto.AimsunSF.v6.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.MyProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.MyProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.MyProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.MyProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AimsunSF.v6.Project.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectRole role = 1;
 * @return {!proto.AimsunSF.v6.ProjectRole}
 */
proto.AimsunSF.v6.MyProject.prototype.getRole = function() {
  return /** @type {!proto.AimsunSF.v6.ProjectRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AimsunSF.v6.ProjectRole} value
 * @return {!proto.AimsunSF.v6.MyProject} returns this
 */
proto.AimsunSF.v6.MyProject.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Project project = 2;
 * @return {?proto.AimsunSF.v6.Project}
 */
proto.AimsunSF.v6.MyProject.prototype.getProject = function() {
  return /** @type{?proto.AimsunSF.v6.Project} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Project, 2));
};


/**
 * @param {?proto.AimsunSF.v6.Project|undefined} value
 * @return {!proto.AimsunSF.v6.MyProject} returns this
*/
proto.AimsunSF.v6.MyProject.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.MyProject} returns this
 */
proto.AimsunSF.v6.MyProject.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.MyProject.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ProjectId.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ProjectId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ProjectId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectId.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ProjectId}
 */
proto.AimsunSF.v6.ProjectId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ProjectId;
  return proto.AimsunSF.v6.ProjectId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ProjectId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ProjectId}
 */
proto.AimsunSF.v6.ProjectId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ProjectId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ProjectId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ProjectId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectId.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectId} returns this
 */
proto.AimsunSF.v6.ProjectId.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ProjectUser.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ProjectUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ProjectUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    role: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ProjectUser}
 */
proto.AimsunSF.v6.ProjectUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ProjectUser;
  return proto.AimsunSF.v6.ProjectUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ProjectUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ProjectUser}
 */
proto.AimsunSF.v6.ProjectUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 6:
      var value = /** @type {!proto.AimsunSF.v6.ProjectRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ProjectUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ProjectUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ProjectUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string salutation = 3;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string firstName = 4;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastName = 5;
 * @return {string}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProjectRole role = 6;
 * @return {!proto.AimsunSF.v6.ProjectRole}
 */
proto.AimsunSF.v6.ProjectUser.prototype.getRole = function() {
  return /** @type {!proto.AimsunSF.v6.ProjectRole} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.AimsunSF.v6.ProjectRole} value
 * @return {!proto.AimsunSF.v6.ProjectUser} returns this
 */
proto.AimsunSF.v6.ProjectUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AimsunSF.v6.ProjectInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.ProjectInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.ProjectInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.AimsunSF.v6.Project.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.AimsunSF.v6.ProjectUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.ProjectInfo}
 */
proto.AimsunSF.v6.ProjectInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.ProjectInfo;
  return proto.AimsunSF.v6.ProjectInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.ProjectInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.ProjectInfo}
 */
proto.AimsunSF.v6.ProjectInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.Project;
      reader.readMessage(value,proto.AimsunSF.v6.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.AimsunSF.v6.ProjectUser;
      reader.readMessage(value,proto.AimsunSF.v6.ProjectUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.ProjectInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.ProjectInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.ProjectInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AimsunSF.v6.Project.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.AimsunSF.v6.ProjectUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.AimsunSF.v6.Project}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.getProject = function() {
  return /** @type{?proto.AimsunSF.v6.Project} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Project, 1));
};


/**
 * @param {?proto.AimsunSF.v6.Project|undefined} value
 * @return {!proto.AimsunSF.v6.ProjectInfo} returns this
*/
proto.AimsunSF.v6.ProjectInfo.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.ProjectInfo} returns this
 */
proto.AimsunSF.v6.ProjectInfo.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProjectUser users = 2;
 * @return {!Array<!proto.AimsunSF.v6.ProjectUser>}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.AimsunSF.v6.ProjectUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AimsunSF.v6.ProjectUser, 2));
};


/**
 * @param {!Array<!proto.AimsunSF.v6.ProjectUser>} value
 * @return {!proto.AimsunSF.v6.ProjectInfo} returns this
*/
proto.AimsunSF.v6.ProjectInfo.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AimsunSF.v6.ProjectUser}
 */
proto.AimsunSF.v6.ProjectInfo.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.AimsunSF.v6.ProjectUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AimsunSF.v6.ProjectInfo} returns this
 */
proto.AimsunSF.v6.ProjectInfo.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.CreateProject.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.CreateProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.CreateProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.CreateProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.AimsunSF.v6.Project.toObject(includeInstance, f),
    admin: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.CreateProject}
 */
proto.AimsunSF.v6.CreateProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.CreateProject;
  return proto.AimsunSF.v6.CreateProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.CreateProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.CreateProject}
 */
proto.AimsunSF.v6.CreateProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AimsunSF.v6.Project;
      reader.readMessage(value,proto.AimsunSF.v6.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.CreateProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.CreateProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.CreateProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.CreateProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AimsunSF.v6.Project.serializeBinaryToWriter
    );
  }
  f = message.getAdmin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.AimsunSF.v6.Project}
 */
proto.AimsunSF.v6.CreateProject.prototype.getProject = function() {
  return /** @type{?proto.AimsunSF.v6.Project} */ (
    jspb.Message.getWrapperField(this, proto.AimsunSF.v6.Project, 1));
};


/**
 * @param {?proto.AimsunSF.v6.Project|undefined} value
 * @return {!proto.AimsunSF.v6.CreateProject} returns this
*/
proto.AimsunSF.v6.CreateProject.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AimsunSF.v6.CreateProject} returns this
 */
proto.AimsunSF.v6.CreateProject.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AimsunSF.v6.CreateProject.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string admin = 2;
 * @return {string}
 */
proto.AimsunSF.v6.CreateProject.prototype.getAdmin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.CreateProject} returns this
 */
proto.AimsunSF.v6.CreateProject.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.AddProjectUserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.AddProjectUserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AddProjectUserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sendemail: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.AddProjectUserData}
 */
proto.AimsunSF.v6.AddProjectUserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.AddProjectUserData;
  return proto.AimsunSF.v6.AddProjectUserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.AddProjectUserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.AddProjectUserData}
 */
proto.AimsunSF.v6.AddProjectUserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.AddProjectUserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.AddProjectUserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.AddProjectUserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSendemail();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddProjectUserData} returns this
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.AddProjectUserData} returns this
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool sendEmail = 3;
 * @return {boolean}
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.getSendemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AimsunSF.v6.AddProjectUserData} returns this
 */
proto.AimsunSF.v6.AddProjectUserData.prototype.setSendemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.RemoveProjectUserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.RemoveProjectUserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RemoveProjectUserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.RemoveProjectUserData}
 */
proto.AimsunSF.v6.RemoveProjectUserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.RemoveProjectUserData;
  return proto.AimsunSF.v6.RemoveProjectUserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.RemoveProjectUserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.RemoveProjectUserData}
 */
proto.AimsunSF.v6.RemoveProjectUserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.RemoveProjectUserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.RemoveProjectUserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.RemoveProjectUserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.RemoveProjectUserData} returns this
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.RemoveProjectUserData} returns this
 */
proto.AimsunSF.v6.RemoveProjectUserData.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.toObject = function(opt_includeInstance) {
  return proto.AimsunSF.v6.UpdateProjectUserRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UpdateProjectUserRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AimsunSF.v6.UpdateProjectUserRole}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AimsunSF.v6.UpdateProjectUserRole;
  return proto.AimsunSF.v6.UpdateProjectUserRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AimsunSF.v6.UpdateProjectUserRole}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!proto.AimsunSF.v6.ProjectRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AimsunSF.v6.UpdateProjectUserRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AimsunSF.v6.UpdateProjectUserRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.UpdateProjectUserRole} returns this
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AimsunSF.v6.UpdateProjectUserRole} returns this
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProjectRole role = 3;
 * @return {!proto.AimsunSF.v6.ProjectRole}
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.getRole = function() {
  return /** @type {!proto.AimsunSF.v6.ProjectRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.AimsunSF.v6.ProjectRole} value
 * @return {!proto.AimsunSF.v6.UpdateProjectUserRole} returns this
 */
proto.AimsunSF.v6.UpdateProjectUserRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.AimsunSF.v6.UserTokenStatus = {
  FORBIDDEN: 0,
  NOTSET: 1,
  SET: 2
};

/**
 * @enum {number}
 */
proto.AimsunSF.v6.UserType = {
  STANDARD: 0,
  ADMINISTRATOR: 1,
  BILLING: 2
};

/**
 * @enum {number}
 */
proto.AimsunSF.v6.LicenseType = {
  PROCESSSTANDALONE: 0,
  PROCESSNETWORK: 1,
  STATIONSTANDALONE: 2
};

/**
 * @enum {number}
 */
proto.AimsunSF.v6.SeatAssignmentState = {
  INACTIVE: 0,
  ACTIVE: 1,
  CANCELLED: 2
};

/**
 * @enum {number}
 */
proto.AimsunSF.v6.FileType = {
  GENERIC: 0,
  QUOTE: 1,
  BILL: 2
};

/**
 * @enum {number}
 */
proto.AimsunSF.v6.ProjectRole = {
  READER_ROLE: 0,
  EDITOR_ROLE: 1,
  ADMINISTRATOR_ROLE: 2
};

goog.object.extend(exports, proto.AimsunSF.v6);
